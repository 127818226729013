import React,{useState} from "react";
import Input from "../../../../../components/Supervisor/input";
import classes from "../editor.module.scss";

const RulesForm = ({
page,
updatePageData,
translations,
selectedLanguage,
updateTranslation,
induction_id
}) => {


const [rules, setRules] = useState(page?.data?.rules);

const updateRule = (item, value) => {
    
    var updatedRules = [...rules];
    updatedRules[item] = {...rules[item], content:value};
    setRules(updatedRules);
    updatePageData({key:'rules',value:updatedRules},true); //preview only
    
    updateTranslation(selectedLanguage.code, rules[item].content_id, value);
}


  return <div>
        
        <br />
        <h4 className={classes.formHeading}>Rules List</h4>
        
        {rules.map((item,index) =>
                          
            <>           
            <Input
                hasTooltip={true}
                order="column"
                value={item?.content_id ? translations[selectedLanguage.code][item?.content_id] : ''}
                name={"rule_"+index}
                characterLimit={120}
                onChange={(e) => {
                    updateRule(index, e.target.value);
                }}
                label={"Rule "+(index+1)}
                placeholder={"rule here"}
                error={""}
                tooltipText={
                "This is a site rule"
                }
            />
            <br />
            </>
        )}

        <button
            id={"add_a_rule"}
            type="submit"
            style={{width: '100px'}}
            onClick={function(){ 
                var updatedRules = [...rules];
                
                const newId = updatedRules.length + 1;
                //add a new one
                updatedRules.push({
                  content: "<p>Lorem ipsum dolor sit amet.</p>",
                  content_id: "induction." + induction_id + ".page." + page?.id + ".content." + newId,
                  image: ""
                });

                setRules(updatedRules);
                updatePageData({key:'rules',value:updatedRules},false); //update real data
                updatePageData({key:'rules',value:updatedRules},true); //update preview
            }}
            disabled={false}
            className={classes.darkButton}
            >
            Add a rule
        </button>


  </div>;
};

export default RulesForm;