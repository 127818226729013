import React,{ useRef } from "react";
import Input from "../../../../../components/Supervisor/input";
import classes from "../editor.module.scss";
import { useDrag, useDrop} from 'react-dnd';

import pageIcon from "../icons/page_icon.png";
import pageIconSelected from "../icons/induction_check.svg";
import pageItemHeaderIcon from "../icons/pageitem_header.png";

const PageItem = ({item,isSelected,onClick,translations,selectedLanguage, 
                   id, index, moveItem
                 }) => 
{

    const ref = useRef(null)
    const [, drop] = useDrop({
      accept: 'Page',
      hover(item, monitor) {
        if (!ref.current) {
          return
        }
        const dragIndex = item.index
        const hoverIndex = index
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return
        }
        // Determine rectangle on screen
        const hoverBoundingRect = ref.current.getBoundingClientRect()
        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
        // Determine mouse position
        const clientOffset = monitor.getClientOffset()
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return
        }
        // Time to actually perform the action
        moveItem(dragIndex, hoverIndex)
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex
      },
    })
    const [{ isDragging }, drag] = useDrag({
      item: { type: 'Page', id, index },
      type: 'Page',
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    })
    const opacity = isDragging ? 0.2 : 1
    drag(drop(ref))


  return <div className={isSelected ? classes.pageItemSelected : classes.pageItem} onClick={() => onClick(item)} ref={ref} style={{ opacity }}>
            <div className={classes.pageItemHeader}> <img src={pageItemHeaderIcon}/> </div>
            <img src={isSelected ? pageIconSelected : pageIcon} />
            <span className={classes.pageItemTitle}>{ translations[selectedLanguage.code] ? translations[selectedLanguage.code][item.title_id] ? translations[selectedLanguage.code][item.title_id]: item?.title : item?.title}</span>
         </div>;
};

export default PageItem;