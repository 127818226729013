// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  CREATE_ASSESSMENT,
  CREATE_ASSESSMENT_ERROR,
  CREATE_ASSESSMENT_SUCCESS,
} from "../../constants";
import axios from "../../../../services/api";
import { push } from "connected-react-router";
import { success } from "../../../../main";
import { isEmpty, isNil } from "lodash";

function* createAssessmentRequest(data) {
  try {
    const values = data;
    const params = new URLSearchParams();
    
    //console.log('all data',values);

    params.append("induction_id", values.induction_id);

    if (values.name)
        params.append("name", values.name);

    const response = yield call(axios.post, `/assessment/`, params);
    if (response.data.error) {
      yield put({
        type: CREATE_ASSESSMENT_ERROR,
        data: response.error,
      });
    } else {
      console.log("response", response);
      yield put({
        type: CREATE_ASSESSMENT_SUCCESS,
        data: response.data,
      });
    }
    //yield call(success, `Induction Updated Succesfully`);
  } catch (error) {
    yield put({
      type: CREATE_ASSESSMENT_ERROR,
      data: error,
    });
  }
}

export default function* createAssessmentRequestSaga() {
  yield takeLatest(CREATE_ASSESSMENT, createAssessmentRequest);
}
