import React from "react";
import Input from "../../../../../components/Supervisor/input";
import classes from "../editor.module.scss";
import Select from "react-select";

const ChapterSettingsForm = ({page,updatePageData, chapter, updateSelectedRule}) => {

  const iconTypes = [{value:'ImageAndText',label:"Image & Text"},
                     {value:'FlipCards',label:"Flipcard"},
                     {value:'Hotspots',label:"Hotspots"},
                     {value:'CardSort',label:"CardSort"},
                     {value:'Carousel-Block',label:"Carousel"}
                    ];
   
  const updateChapterIcon = (icon) => {
  
      var chapterUpdated = {...chapter};
      chapterUpdated.iconType = icon;

      //now put it back
      var allChapters = [...page?.data.rules];

      for(var i=0; i<allChapters.length; i++)
      {
          if(allChapters[i].id == chapter.id)
            allChapters[i] = chapterUpdated;
      }

      var updatedPageData = {...page,data:{...page.data,rules:allChapters}}

      console.log('updateChapterIcon',updatedPageData);

      updatePageData({key:'rules',value:allChapters});
      updatePageData({key:'rules',value:allChapters},true); //preview only

      updateSelectedRule(chapterUpdated);
      


  }

  return <div>
        
        <br />
        <h4 className={classes.formHeading}>Chapter Settings</h4>

        <p>Chapter Icon:</p>
        <Select
            styles={{
                control: () => ({
                width: "100%",
                height: 44,
                display: "flex",
                background: "#f8f8f8",
                paddingLeft: "10px",
                color: "#40507e",
                border: "1px solid #E0E0E0",
                boxSizing: "border-box",
                borderRadius: "6px",
                boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.25)",
                fontFamily: "Open Sans",
                fontSize: "14px",
                fontWeight: "bold",
                }),
            }}
            placeholder={"Correct Option"}
            onChange={(val) => {
                updateChapterIcon(val.value);
            }}
            tabSelectsValue={''}
            value={iconTypes.filter(
                (option) => option.value === chapter?.iconType
              )}
            options={iconTypes}
            isSearchable={false}
        />


  </div>;
};

export default ChapterSettingsForm;